import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";
import { DateRangePicker } from "react-date-range";


const ShowInformationType = [
  {
    value: "1",
    label: "Vehicle",
  },
  { value: "2", label: "Driver" },
  { value: "3", label: "Driver Status" },
];

const DrivingBehaviorEvent = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChange = (range) => {
    setDateRange(range);
  };
  const [AlarmsData, setAlarmsData] = useState({
    show_information_by: "",
    vehicle_id: "",
    driver_id: "",
    include_note: "",
    subject: "",
    email: "",
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [DriverList, setDriverList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [open, setOpen] = useState(false);

  const formattedStartDate = selectionRange.startDate.toLocaleDateString();
  const formattedEndDate = selectionRange.endDate.toLocaleDateString();

  const onDatepickerOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setAlarmsData({
      ...AlarmsData,
      include_note: newValue,
    });
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>DRIVING BEHAVIOR EVENTS</h6>
              <h6>
                This report contains the detailed information about the events
                triggered for the Driving behavior score of each driver.
              </h6>
              <div className="d-flex justify-end">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
                <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Date"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                        value={`${formattedStartDate} - ${formattedEndDate}`}
                      />
                      <span
                        class="input-group-text"
                        id="basic-addon1"
                        onClick={onDatepickerOpen}
                      >
                        <i class="bi bi-calendar-event"></i>
                      </span>
                    </div>
                    {open === true ? (
                      <div className="position-relative">
                        <DateRangePicker
                          ranges={[selectionRange]}
                          onChange={handleSelect}
                        />
                        <div
                          className="date-range-overlay"
                          onClick={onDatepickerOpen}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label">
                      {"Show information by"}
                    </Label>

                    <Select
                      options={ShowInformationType}
                      value={ShowInformationType.find(
                        (option) =>
                          option.value === AlarmsData.show_information_by
                      )}
                      onChange={(selectedOption) =>
                        setAlarmsData((prevData) => ({
                          ...prevData,
                          show_information_by: selectedOption.value,
                        }))
                      }
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <Label className="col-form-label">{"Vehicle"}</Label>
                  <div className="input-div">
                    <Select
                      options={vehicleList}
                      value={vehicleList?.find(
                        (option) =>
                          option.value === JSON.stringify(AlarmsData.vehicle_id)
                      )}
                      onChange={(selectedOption) =>
                        setAlarmsData((prevData) => ({
                          ...prevData,
                          vehicle_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Vehicle"}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Driver"}</Label>

                  <Select
                    options={DriverList}
                    value={DriverList?.find(
                      (option) =>
                        option.value === JSON.stringify(AlarmsData.driver_id)
                    )}
                    onChange={(selectedOption) =>
                      setAlarmsData((prevData) => ({
                        ...prevData,
                        driver_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Driver"}
                  />
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Alarms"}</Label>

                  <Select
                    options={DriverList}
                    value={DriverList?.find(
                      (option) =>
                        option.value === JSON.stringify(AlarmsData.driver_id)
                    )}
                    onChange={(selectedOption) =>
                      setAlarmsData((prevData) => ({
                        ...prevData,
                        driver_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Driver"}
                  />
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">Include notes</Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="tachographTypeCheckbox"
                        checked={AlarmsData.include_note}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="tachographTypeCheckbox"
                      >
                        Include notes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`Save`}
                  className={`btn-square `}
                >{`Save`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setAlarmsData({
                          ...AlarmsData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={AlarmsData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setAlarmsData({
                          ...AlarmsData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={AlarmsData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DrivingBehaviorEvent;
