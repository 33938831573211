import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";
import { getFixedAllCostList, getVariableAllCostList } from "../../services/ManagementService";

const ShowInformationType = [
  {
    value: "1",
    label: "Vehicle",
  },
  { value: "2", label: "Driver" },
  { value: "3", label: "Driver Status" },
];

const DetailedCostReport = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChange = (range) => {
    setDateRange(range);
  };
  const [detailsCostData, setDetailsCostData] = useState({
    fixed_type_id: "",
    vehicle_id: "",
    variable_type_id: "",
    show_data_group: 0,
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [fixedCosAlltList, setfixedAllCostList] = useState([]);
  const [variableCostAllList, setvariableCostAllList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDetailsCostData({
      ...detailsCostData,
      show_data_group: newValue,
    });
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getFixedAllCostList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setfixedAllCostList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(() => {
    let data = params;
    getVariableAllCostList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        list.push({ value: "All", label: "All" });
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setvariableCostAllList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle list:", err);
      });
  }, [params]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>DETAILED COSTS</h6>
              <h6>
                This report lists all costs, variable and fixed, occurred within
                a certain time period.
              </h6>
              <div className="d-flex justify-content-end">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
                <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>
                    <DatePicker
                      selected={dateRange}
                      onChange={handleChange}
                      range
                      dateFormat="yyyy-MM-dd"
                      calendarPosition="bottom-center"
                      placeholder="Select Date"
                      style={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                        height: "40px",
                        margin: "1px 0",
                        padding: "2px 5px",
                        paddingRight: "30px",
                        width: "100%",
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Vehicle"}</Label>
                  <div className="input-div">
                    <Select
                      options={vehicleList}
                      value={vehicleList?.find(
                        (option) =>
                          option.value === JSON.stringify(detailsCostData.vehicle_id)
                      )}
                      onChange={(selectedOption) =>
                        setDetailsCostData((prevData) => ({
                          ...prevData,
                          vehicle_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Vehicle"}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">
                    {"Select Fixed Type"}
                  </Label>
                  <div className="input-div">
                    <Select
                      options={fixedCosAlltList}
                      value={fixedCosAlltList?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(detailsCostData.fixed_type_id)
                      )}
                      onChange={(selectedOption) =>
                        setDetailsCostData((prevData) => ({
                          ...prevData,
                          fixed_type_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Fixed Type"}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">
                    {"Select Variable Type"}
                  </Label>
                  <div className="input-div">
                    <Select
                      options={variableCostAllList}
                      value={variableCostAllList?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(detailsCostData.variable_type_id)
                      )}
                      onChange={(selectedOption) =>
                        setDetailsCostData((prevData) => ({
                          ...prevData,
                          variable_type_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Variable Type"}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">
                    Show data grouped by cost type
                  </Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="show_data_group"
                        checked={detailsCostData?.show_data_group === 1}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="show_data_group"
                      >
                        Show data grouped by cost type
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`Save`}
                  className={`btn-square `}
                >{`Save`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setDetailsCostData({
                          ...detailsCostData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={detailsCostData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setDetailsCostData({
                          ...detailsCostData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={detailsCostData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DetailedCostReport;
