import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import {
  getDeviceVehcileAllTripList,
  getDeviceVehcileTripList,
  getVehicleList,
} from "../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../utils";
import Select from "react-select";
import ConfigDB from "../config";

const tableLengthList = ConfigDB.data.dataTableLength;

const VehicleTripList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [alldevicedelayVehicleTripData, setDeviceAllDelayVehicleTripData] =
    useState([]);
  const [vehicleDate, setVehicleDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [vehicleDates, setVehicleDates] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicle, setVehicle] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    vehicleId: vehicle.vehicleId,
    page: 1,
    search: "",

    is_reload: false,
  });

  const state = useLocation();

  useEffect(() => {
    if (state[0] || vehicleDate) {
      let data = {
        startDate: vehicleDate,
        device_imei: vehicle ? vehicle.vehicleId : "",
        search: params.search,
      };
      getDeviceVehcileAllTripList(data)
        .then((response) => {
          setDeviceAllDelayVehicleTripData(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      setDeviceAllDelayVehicleTripData([]);
    }
  }, [state, vehicleDate, vehicle, params]);

  useEffect(() => {
    let data = params;
    getVehicleList(data)
      .then((response) => {
        response = response.data;
        let list = response.data.map((vehicle) => ({
          value: vehicle.device_imei,
          label: vehicle.vehicleName,
        }));
        setVehicleList(list);
      })
      .catch((err) => {});
  }, [params]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);

    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "vehicle") {
      paramsObj.page = 1;
      paramsObj[type === "vehicle" ? "vehicleId" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };

  return (
    <div>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Trips</h5>
        </div>
      </div>
      <div className="container-event">
        <div className="page-header-row company-section-Area">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between company-section">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Arrival</p>
                        <div className="input-group">
                          <input
                            type="date"
                            id="vehicleDate"
                            className="form-control"
                            value={vehicleDate}
                            onChange={(e) => setVehicleDate(e.target.value)}
                            placeholder="Vehicle Date"
                            style={{ marginBottom: "-15px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Departure</p>
                        <div className="input-group">
                          <input
                            type="date"
                            id="vehicleDate"
                            className="form-control"
                            value={vehicleDates}
                            onChange={(e) => setVehicleDates(e.target.value)}
                            placeholder="Vehicle Date"
                            style={{ marginBottom: "-15px" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Vehicle</p>
                        <div style={{ width: "100%" }}>
                          <Select
                            options={vehicleList}
                            value={vehicleList?.find(
                              (option) => option.value === vehicle
                            )}
                            onChange={(selectedOption) =>
                              setVehicle((prevData) => ({
                                ...prevData,
                                vehicleId: selectedOption.value,
                              }))
                            }
                            placeholder={"Select Vehicle"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="selected-block selected-mobile">
                    <p>Search</p>
                    <div className="search-bar">
                      <i className="bi bi-search"></i>
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Trip`}</th>
                <th scope={`col`}>{`Departure Time`}</th>
                <th scope={`col`}>{`Departure`}</th>
                <th scope={`col`}>{`Arrival Time`}</th>
                <th scope={`col`}>{`Arrival`}</th>
                <th scope={`col`}>{`Mileage`}</th>
                <th scope={`col`}>{`Duration`}</th>
                <th scope={`col`}>{`Arrival Odometer`}</th>
                <th scope={`col`}>{`GPS Mileage`}</th>
                <th scope={`col`}>{`CANBUS mileage`}</th>
                <th scope={`col`}>{`Cost`}</th>
                <th scope={`col`}>{`Department`}</th>
                <th scope={`col`}>{`Stop`}</th>
              </tr>
            </thead>

            {vehicleDate ? (
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {alldevicedelayVehicleTripData &&
                      alldevicedelayVehicleTripData?.map((item, i) => {
                        let formattedStartDate = moment(
                          item.tripStartTime
                        ).format("YYYY-MM-DD HH:mm:ss");
                        let formattedEndDate = moment(item.tripEndTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        const startTime = moment(item.tripStartTime);
                        const endTime = moment(item.tripEndTime);
                        const duration = moment.duration(
                          endTime.diff(startTime)
                        );

                        const formattedDuration = `${Math.floor(
                          duration.asHours()
                        )}Hour:${duration.minutes()}Minutes`;
                        return (
                          <>
                            <tr>
                              <td>{item.trip}</td>
                              <td>{formattedStartDate}</td>
                              <td>{item.departureName}</td>
                              <td>{formattedEndDate}</td>
                              <td>{item.arrivalName}</td>
                              <td>{item.mileage}</td>
                              <td>{item.tripDuration}</td>
                              <td>{item.arrivalOddometer}</td>
                              <td>{item.GPS_mileage}</td>
                              <td>{item.CANBUS_mileage}</td>
                              <td>{item.cost}</td>
                              <td>{item.department || "Segment"}</td>
                              <td>{formattedDuration}</td>
                            </tr>
                          </>
                        );
                      })}
                  </>
                )}

                {alldevicedelayVehicleTripData?.length === 0 && (
                  <tr>
                    <td
                      colSpan={7}
                      className={`text-center`}
                    >{`Record Not Found`}</td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {state?.state &&
                      state?.state?.map((item, i) => {
                        let formattedStartDate = moment(
                          item.tripStartTime
                        ).format("YYYY-MM-DD HH:mm:ss");
                        let formattedEndDate = moment(item.tripEndTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        const startTime = moment(item.tripStartTime);
                        const endTime = moment(item.tripEndTime);
                        const duration = moment.duration(
                          endTime.diff(startTime)
                        );

                        const formattedDuration = `${Math.floor(
                          duration.asHours()
                        )}Hour:${duration.minutes()}Minutes`;
                        return (
                          <>
                            <tr>
                              <td>{item.trip}</td>
                              <td>{formattedStartDate}</td>
                              <td>{formattedEndDate}</td>
                              <td>{formattedDuration}</td>
                              {/* <td>
                              {item?.logs?.map((logItem, index) => (
                                <td>{logItem[0]?.total_odpmeter}</td>
                              ))}
                            </td> */}
                            </tr>
                          </>
                        );
                      })}
                  </>
                )}

                {state?.state?.length === 0 && (
                  <tr>
                    <td
                      colSpan={7}
                      className={`text-center`}
                    >{`Record Not Found`}</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default VehicleTripList;
