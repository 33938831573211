import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import { getDriverList } from "../../services/TripService";

const showReport = [
  {
    value: 1,
    label: "Single Day",
  },
  { value: 2, label: "Multiple Day" },
];

const showDayOfWeek = [
  {
    value: 1,
    label: "Per Column",
  },
  { value: 2, label: "Per Row" },
];

const DailySummaryReports = ({ setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChange = (range) => {
    setDateRange(range);
  };
  const [DailyData, setDailyData] = useState({
    show_report: "",
    show_days_of_week: "",
    alarm_to_show: "",
    aggregate_alarms: "",
    show_beginning: "",
    show_end: "",
    number_of_trips: "",
    gps_mileage: "",
    canbus_mileage: "",
    travel_time: "",
    average_speed: "",
    first_driver: "",
    last_driver: "",
    different_driver: "",
    fuel_consumption: "",
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [DriverList, setDriverList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleSendEmailOpen = () => {
    setModifyModal(true);
  };

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      aggregate_alarms: newValue,
    });
  };

  const handleCheckboxChangeShowBeginning = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      show_beginning: newValue,
    });
  };

  const handleCheckboxChangeShowEnd = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      show_end: newValue,
    });
  };

  const handleCheckboxChangeTrips = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      number_of_trips: newValue,
    });
  };

  const handleCheckboxChangeGPS = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      gps_mileage: newValue,
    });
  };
  const handleCheckboxChangeCanBus = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      canbus_mileage: newValue,
    });
  };

  const handleCheckboxChangeTrivelTime = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      travel_time: newValue,
    });
  };

  const handleCheckboxChangeAverageSpeed = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      average_speed: newValue,
    });
  };

  const handleCheckboxChangeFirstDriver = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      first_driver: newValue,
    });
  };

  const handleCheckboxChangeLastDriver = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      last_driver: newValue,
    });
  };

  const handleCheckboxChangeDifferentDriver = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      different_driver: newValue,
    });
  };

  const handleCheckboxChangeFuelConsumption = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDailyData({
      ...DailyData,
      fuel_consumption: newValue,
    });
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getDriverList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setDriverList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Daily Summary</h6>
              <h6>
                This report presents the daily summary of the activity of the
                vehicles. Select the indicators you wish to see represented in
                the report.
              </h6>
              <div className="d-flex" style={{ justifyContent: "end" }}>
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
                <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>
                    <DatePicker
                      selected={dateRange}
                      onChange={handleChange}
                      range
                      dateFormat="yyyy-MM-dd"
                      calendarPosition="bottom-center"
                      placeholder="Select Date"
                      style={{
                        border: "1px solid #CCCCCC",
                        borderRadius: "5px",
                        height: "40px",
                        margin: "1px 0",
                        padding: "2px 5px",
                        paddingRight: "30px",
                        width: "100%",
                      }}
                    />
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Show Report"}</Label>
                  <div className="input-div">
                    <Select
                      options={showReport}
                      value={showReport?.find(
                        (option) => option.value === DailyData.show_report
                      )}
                      onChange={(selectedOption) =>
                        setDailyData((prevData) => ({
                          ...prevData,
                          show_report: selectedOption.value,
                        }))
                      }
                      placeholder={"Select show report"}
                    />
                  </div>
                </div>

                {DailyData?.show_report === 2 && (
                  <div className="row">
                    <div className="col-md-6">
                      <Label className="col-form-label">
                        {"Show days of the week"}
                      </Label>
                      <div className="input-div">
                        <Select
                          options={showDayOfWeek}
                          value={showDayOfWeek?.find(
                            (option) =>
                              option.value === DailyData.show_days_of_week
                          )}
                          onChange={(selectedOption) =>
                            setDailyData((prevData) => ({
                              ...prevData,
                              show_days_of_week: selectedOption.value,
                            }))
                          }
                          placeholder={"Select days of the week"}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        {"Alarms to show"}
                      </Label>
                      <div className="input-div">
                        <Select
                          options={showDayOfWeek}
                          value={showDayOfWeek?.find(
                            (option) =>
                              option.value === DailyData.show_days_of_week
                          )}
                          onChange={(selectedOption) =>
                            setDailyData((prevData) => ({
                              ...prevData,
                              show_days_of_week: selectedOption.value,
                            }))
                          }
                          placeholder={"Alarms to show"}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Aggregate Alarms</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tachographTypeCheckbox"
                            checked={DailyData.aggregate_alarms}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tachographTypeCheckbox"
                          >
                            Aggregate Alarms
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Show beginning</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tachographTypeCheckbox"
                            checked={DailyData.show_beginning}
                            onChange={handleCheckboxChangeShowBeginning}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tachographTypeCheckbox"
                          >
                            Show beginning
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Show End</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="show_end"
                            checked={DailyData.show_end}
                            onChange={handleCheckboxChangeShowEnd}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="show_end"
                          >
                            Show End
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Show number of trips
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="number_of_trips"
                            checked={DailyData.number_of_trips}
                            onChange={handleCheckboxChangeTrips}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="number_of_trips"
                          >
                            Show number of trips
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Show GPS mileage</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="tachographTypeCheckbox"
                            checked={DailyData.show_beginning}
                            onChange={handleCheckboxChangeGPS}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tachographTypeCheckbox"
                          >
                            Show GPS mileage
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Show CANBus mileage when available
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="canbus_mileage"
                            checked={DailyData.canbus_mileage}
                            onChange={handleCheckboxChangeCanBus}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="canbus_mileage"
                          >
                            Show CANBus mileage when available
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Travel time</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="travel_time"
                            checked={DailyData.travel_time}
                            onChange={handleCheckboxChangeTrivelTime}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="travel_time"
                          >
                            Travel time
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Average speed</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="average_speed"
                            checked={DailyData.average_speed}
                            onChange={handleCheckboxChangeAverageSpeed}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="average_speed"
                          >
                            Average speed
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">First driver</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="first_driver"
                            checked={DailyData.first_driver}
                            onChange={handleCheckboxChangeFirstDriver}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="first_driver"
                          >
                            First driver
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Last driver</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="last_driver"
                            checked={DailyData.last_driver}
                            onChange={handleCheckboxChangeLastDriver}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="last_driver"
                          >
                            Last driver
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">
                        Different drivers
                      </Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="different_driver"
                            checked={DailyData.different_driver}
                            onChange={handleCheckboxChangeDifferentDriver}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="different_driver"
                          >
                            Different drivers
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Label className="col-form-label">Fuel consumption</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="fuel_consumption"
                            checked={DailyData.fuel_consumption}
                            onChange={handleCheckboxChangeFuelConsumption}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="fuel_consumption"
                          >
                            Fuel consumption
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`Save`}
                  className={`btn-square `}
                >{`Save`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setDailyData({
                          ...DailyData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={DailyData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setDailyData({
                          ...DailyData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={DailyData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DailySummaryReports;
