import React, { useEffect, useRef, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import truckImg from "../../assets/images/truck-m.svg";
import tvImg from "../../assets/images/tv.svg";
import exclamationImg from "../../assets/images/exclamation.svg";
import userImg from "../../assets/images/user-img.png";
import { authActions } from "../../store";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalFooter,
  Form,
} from "reactstrap";
import { getLanguageListAll } from "../../services/Common";
import Utils from "../../utils";
import { toast } from "react-toastify";
import ConfigDB from "../../config";
import {
  getVehicleHistoryDetail,
  getVehicleList,
} from "../../services/VehicleService";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Select from "react-select";
import moment from "moment";
import loginLogoImgs from "../../assets/images/logo-1.png";
import { getFilterAllList } from "../../services/ManagementService";

function RightHeader({ setActiveTab, activeTab }) {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const auth = useSelector((x) => x.auth.value);
  const [profile, setProfile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [lang, setLang] = useState("en");
  const [langName, setLangName] = useState("English");
  const [langIcon, setLangIcon] = useState("https://flagcdn.com/us.svg");
  const [languageData, setLanguageData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [params] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [vehicleList, setVehicleList] = useState([]);
  const [modifyModal, setModifyModal] = useState(false);
  const [modifyModalNotifications, setModifyModalNotifications] =
    useState(false);
  const [filter, setFilter] = useState(null);
  const [filterList, setFilterList] = useState([]);

  const [vehicleHistoryDetails, setVehicleHistoryDetails] = useState({
    vehicleAlarm: [],
    vehicleMaintance: [],
    pagination: {
      page: 1,
      totalPagesAlarm: 1,
      totalPagesMaintenance: 1,
    },
  });

  const [vehicleLists, setVehicleLists] = useState([]);

  const [vehicle, setVehicle] = useState("");
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const listInnerRef = useRef();

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const isAdminRole = JsonParse?.user_role === ConfigDB.data.ROLE_ADMIN;

  const handleScroll = () => {
    if (window.scrollY === 0 && page > 1 && !loading) {
      setPage(page - 1);
      const fetchData = async () => {
        if (!loading && vehicle.vehicleId) {
          setLoading(true);
          try {
            const data = {
              vehicle_id: vehicle.vehicleId,
              page: page,
              pageSize: 10,
            };
            const response = await getVehicleHistoryDetail(data);
            const updatedData = {
              ...vehicleHistoryDetails,
              vehicleAlarm: [
                ...vehicleHistoryDetails.vehicleAlarm,
                ...response.data.data.vehicleAlarm,
              ],
              vehicleMaintance: [
                ...vehicleHistoryDetails.vehicleMaintance,
                ...response.data.data.vehicleMaintance,
              ],
              pagination: response.data.data.pagination,
            };
            setVehicleHistoryDetails(updatedData);
          } catch (error) {
            toast.error(Utils.getErrorMessage(error));
          } finally {
            setLoading(false);
          }
        }
      };
      fetchData();
    }
    setPage(page + 1);
    const fetchData = async () => {
      if (!loading && vehicle.vehicleId) {
        setLoading(true);
        try {
          const data = {
            vehicle_id: vehicle.vehicleId,
            page: page,
            pageSize: 10,
          };
          const response = await getVehicleHistoryDetail(data);
          const updatedData = {
            ...vehicleHistoryDetails,
            vehicleAlarm: [
              ...vehicleHistoryDetails.vehicleAlarm,
              ...response.data.data.vehicleAlarm,
            ],
            vehicleMaintance: [
              ...vehicleHistoryDetails.vehicleMaintance,
              ...response.data.data.vehicleMaintance,
            ],
            pagination: response.data.data.pagination,
          };
          setVehicleHistoryDetails(updatedData);
        } catch (error) {
          toast.error(Utils.getErrorMessage(error));
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (vehicle?.vehicleId && modifyModal) {
      let data = {
        vehicle_id: vehicle?.vehicleId,
      };
      setLoading(true);
      getVehicleHistoryDetail(data)
        .then((response) => {
          setVehicleHistoryDetails(response.data.data);
          setModifyModal(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else if (vehicle?.vehicleId && modifyModalNotifications) {
      let data = {
        vehicle_id: vehicle?.vehicleId,
      };
      setLoading(true);
      getVehicleHistoryDetail(data)
        .then((response) => {
          setVehicleHistoryDetails(response.data.data);
          setModifyModalNotifications(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehicle?.vehicleId]);

  const handleVehicle = () => {
    setModifyModal(true);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      const newFilterId = selectedOption.value;
      setFilter((prevData) => ({
        ...prevData,
        filter_id: newFilterId,
      }));
      localStorage.setItem("filter", newFilterId);
    } else {
      setFilter((prevData) => ({
        ...prevData,
        filter_id: null,
      }));
      localStorage.removeItem("filter");
    }
  };

  const handleVehicleNotifcation = () => {
    setModifyModalNotifications(true);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // if (filter) {
  //   localStorage.setItem("filter", filter.filter_id);
  // }

  // useEffect(() => {
  //   if (filter) {
  //     const fetchVehicleList = async () => {
  //       try {
  //         let data = {
  //           filter_id: filter.filter_id,
  //           limit: tableLengthList[0],
  //           page: 1,
  //           search: "",
  //           is_reload: false,
  //         };
  //         const response = await getVehicleList(data);
  //         setVehicleLists(response.data.data);
  //       } catch (err) {
  //         toast.error(Utils.getErrorMessage(err));
  //       } finally {
  //       }
  //     };

  //     fetchVehicleList();
  //   }
  // }, [filter]);

  useEffect(() => {
    if (auth && auth.user_image) {
      setProfile(auth.user_image);
    }
  }, [auth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      // }
    };

    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const onProfileClicked = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(authActions.logout());
    navigate(`/`);
  };

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages:
            "en,fr,de,es,it,hi,sv,ar,el,ru,zh-CN,sk,cs,da,pt,ja,sl,nl,si,no,pl,ur,ml,hi",
        },
        "google_translate_element"
      );
    };
  }, []);

  useEffect(function () {
    getLanguageListAll()
      .then((response) => {
        setLanguageData(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleLanguage = (e, item) => {
    e.preventDefault();
    const selectedLanguageCode = item.language_code;
    setLang(selectedLanguageCode);
    setDropdownOpen(false);
    let langElement = document.getElementsByClassName("goog-te-combo");
    if (langElement && langElement.length > 0) {
      langElement[0].value = selectedLanguageCode;
      const changeEvent = new Event("change");
      langElement[0].dispatchEvent(changeEvent);

      if (!langElement[0].value) {
        langElement[0].value = selectedLanguageCode;
        const changeEvent = new Event("change");
        langElement[0].dispatchEvent(changeEvent);
      }
      localStorage.setItem("selectedLanguage", selectedLanguageCode);

      let index = languageData.findIndex(
        (x) => x.language_code === selectedLanguageCode
      );
      if (index > -1) {
        setLangName(languageData[index]["language_name"]);
        setLangIcon(languageData[index]["flag_image_url"]);
      }
    }
  };

  useEffect(() => {
    let googtrans = Utils.getCookie("googtrans");
    if (googtrans) {
      let langName = googtrans.replace("/en/", "");
      if (langName) {
        setLang(langName);
        let index = languageData.findIndex((x) => x.language_code === langName);
        if (index > -1) {
          setLangName(languageData[index]["language_name"]);
          setLangIcon(languageData[index]["flag_image_url"]);
        }
      }
    }
  }, [languageData]);

  const onModifyModalClose = (e) => {
    setModifyModal(false);
    setVehicleHistoryDetails([]);
  };
  const onModifyModalCloseNotifications = (e) => {
    setModifyModalNotifications(false);
    setVehicleHistoryDetails([]);
  };

  useEffect(function () {
    getFilterAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setFilterList(list);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="app-header__content">
      <div className="app-header-navabar">
        <div class="navbar-top">
          <div className="app-header__logo">
            <div className="logo-src">
              <NavLink to={`/dashboard`}>
                <img src={loginLogoImgs} alt="logo" />
              </NavLink>
            </div>
          </div>

          <label for="togglericon" class="toggler" checked></label>
          <input type="checkbox" id="togglericon" class="toggler" />

          <ul className="nav">
            <div class="dropdown home-dropdown">
              {!isAdminRole ? (
                <li className="active" onClick={() => navigate("/dashboard")}>
                  <span>Map</span>
                </li>
              ) : null}
            </div>
            <div class="dropdown">
              <label for="toggle-1">Trips & Orders</label>
              <input type="checkbox" id="toggle-1" />
              <ul>
                <li onClick={() => navigate("/vehcile-trip-data")}>
                  <span>Trips</span>
                </li>
                <li onClick={() => navigate("/trip")}>
                  <span>Routes</span>
                </li>
                {/* <li onClick={() => navigate("/invoice")}>
                  <span>Invoice</span>
                </li> */}
                {isAdminRole ? (
                  <li onClick={() => navigate("/order/order-state")}>
                    <span>Order State</span>
                  </li>
                ) : null}
              </ul>
            </div>
            <div class="dropdown">
              <label for="toggle-2">Fleets</label>
              <input type="checkbox" id="toggle-2" />
              <ul>
                {isAdminRole ? (
                  <li onClick={() => navigate("/vehicle-class")}>
                    <span>Vehicle Class</span>
                  </li>
                ) : null}

                <li onClick={() => navigate("/vehicle")}>
                  <span>Vehicles</span>
                </li>
                {!isAdminRole ? (
                  <li onClick={() => navigate("/vehicle-task")}>
                    <span>Maintainence</span>
                  </li>
                ) : null}

                <li onClick={() => navigate("/vehicle-activity")}>
                  <span>Vehicle Activities</span>
                </li>
                <li onClick={() => navigate("/health-dashboard")}>
                  <span>Vehicle Health Management</span>
                </li>
                {isAdminRole ? (
                  <li onClick={() => navigate("/device-health-dashboard")}>
                    <span>Device</span>
                  </li>
                ) : null}
              </ul>
            </div>
            <div class="dropdown">
              <label for="toggle-3">CRM</label>
              <input type="checkbox" id="toggle-3" />
              <ul>
                {/* <li>
                  <span
                    onClick={() => {
                      navigate("/user");
                    }}
                  >
                    Users
                  </span>
                </li> */}
                {/* <li>
                  <span
                    onClick={() => {
                      navigate("/user");
                    }}
                  >
                    Users
                  </span>
                </li> */}
                <li>
                  <span
                    onClick={() => {
                      navigate("/crm");
                    }}
                  >
                    Customer
                  </span>
                </li>

                {/* <li>
                  <span
                    onClick={() => {
                      navigate("/driver");
                    }}
                  >
                    Driver
                  </span>
                </li> */}

                {/* <li onClick={() => navigate("/notification")}>
                  <span>Alerts</span>
                </li> */}
                {/* {!isAdminRole ? (
                  <li onClick={() => navigate("/places")}>
                    <span>Area</span>
                  </li>
                ) : null} */}
                {/* 
                <li onClick={() => navigate("/company-provider")}>
                  <span>API Tokens</span>
                </li> */}
              </ul>
            </div>
            <div class="dropdown">
              <label for="toggle-4">Cost Management</label>
              <input type="checkbox" id="toggle-4" />
              <ul>
                {/* <li onClick={() => navigate("/setting")}>
                  <span>Setting</span>
                </li> */}

                <li onClick={() => navigate("/notification")}>
                  <span>Alerts</span>
                </li>
                <li onClick={() => navigate("/management")}>
                  <span>Management</span>
                </li>
              </ul>
            </div>
            <div class="dropdown">
              <label for="toggle-4">More</label>
              <input type="checkbox" id="toggle-4" />
              <ul>
                <li onClick={() => navigate("/alarm")}>
                  <span>Alarm</span>
                </li>
                <li onClick={() => navigate("/distribution")}>
                  <span>Distribution</span>
                </li>
                <li onClick={() => navigate("/reports")}>
                  <span>Reports</span>
                </li>
              </ul>
            </div>
            {isAdminRole && (
              <div class="dropdown">
                <label for="toggle-4">Admin</label>
                <input type="checkbox" id="toggle-4" />
                <ul>
                  <li onClick={() => navigate("/subscription")}>
                    <span>Subscription</span>
                  </li>
                  <li onClick={() => navigate("/roles")}>
                    <span>Role</span>
                  </li>
                  <li onClick={() => navigate("/company")}>
                    <span>Companies</span>
                  </li>
                  <li onClick={() => navigate("/maintaninaceparm")}>
                    <span>Maintainance</span>
                  </li>{" "}
                  <li onClick={() => navigate("/maintaninacetask")}>
                    <span>Maintainance Task</span>
                  </li>{" "}
                </ul>
              </div>
            )}
          </ul>
        </div>
      </div>
      <div className="app-header-right">
        <div className="col-app-header">
          <ul className="vertical-nav-2 d-none">
            <li>
              <a>
                <img src={truckImg} alt="truck" />
              </a>
            </li>
            <li>
              <a>
                <img src={tvImg} alt="tv" />
              </a>
            </li>
            <li className="danger-link">
              <a>
                <img src={exclamationImg} alt="!" />
              </a>
            </li>
          </ul>
          <div className="caret-left d-none">
            <p>0</p>
          </div>
        </div>

        <div className="header-user-profile">
          <div className="d-flex">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="notranslate"
            >
              <DropdownToggle
                style={{ backgroundColor: "#0dcaf0", borderColor: "#0dcaf0" }}
              >
                <img src={langIcon} style={{ width: "20px" }} /> {langName}
              </DropdownToggle>
              <DropdownMenu>
                {languageData?.map((item, index) => (
                  <DropdownItem
                    key={index}
                    onClick={(e) => handleLanguage(e, item)}
                    toggle={false}
                  >
                    <img src={item.flag_image_url} style={{ width: "20px" }} />
                    <span style={{ padding: "10px" }}>
                      {item.language_name}
                    </span>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="language-block d-none">
            <div id={`google_translate_element`} />
          </div>
        </div>
        <div className="header-user-profile">
          <i className="bi bi-truck bi-icon" onClick={handleVehicle} />
        </div>
        <div className="header-user-profile">
          <i class="bi bi-bell bi-icon" onClick={() => navigate("/alarm")} />
        </div>

        <div className="header-user-profile">
          <div>
            <Select
              options={filterList}
              value={filterList?.find((option) => option.value === filter)}
              onChange={handleChange}
              placeholder="Select Filter"
              isClearable={true}
            />
          </div>
        </div>

        <div className="header-user-derop d-none">
          <div className="label-block">
            Showing{" "}
            <i className="icon">
              <img src={truckImg} alt="" />
            </i>
          </div>
        </div>
        <div className="search-wrapper d-none">
          <select className="form-select" aria-label="Default select example">
            <option>all</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="header-user-profile user-profil" ref={dropdownRef}>
          <div className="profile">
            <div className="img-box">
              <img
                src={profile ? profile : userImg}
                alt="user image"
                onClick={onProfileClicked}
              />
            </div>
          </div>
          <div className={isOpen ? "menu active" : "menu"}>
            <ul>
              <div className="user-div">
                <h3>
                  {JsonParse?.name.charAt(0).toUpperCase() +
                    JsonParse?.name.slice(1).toLowerCase()}
                </h3>

                <h3
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {JsonParse?.user_role_name}
                </h3>
              </div>
              <li>
                <NavLink
                  to={`/profile`}
                  role={`link`}
                  className={activeTab === "general" ? "active" : ""}
                >
                  <i className="bi bi-person-fill"></i>
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink role={`link`} to={`/administration`}>
                  <i className="bi bi-person-bounding-box"></i>
                  Administration
                </NavLink>
              </li>
              <li>
                <NavLink role={`link`} onClick={onLogout}>
                  <i class="bi bi-box-arrow-right"></i>
                  Logout
                </NavLink>
              </li>

              {/* <li>
                <NavLink to={`/profile`} role={`link`}>
                  <i className="bi bi-key-fill"></i>
                  Change Password
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        {/* <div className="header-user-profile">
          <div className="profile">
            <div className="img-box mt-14" onClick={onLogout}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path d="m2 12 5 4v-3h9v-2H7V8z" fill="#27A9E0"></path>
                <path
                  fill="#27A9E0"
                  d="M13.001 2.999a8.938 8.938 0 0 0-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051 2.051 3.08 2.051 4.95-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z"
                ></path>
              </svg>
            </div>
          </div>
        </div> */}
      </div>
      <Modal className="" isOpen={modifyModal}>
        <>
          <div>
            <div className="inner-header py-3">
              <div className="left-block">
                <h5>Vehicle Maintenance History Details</h5>
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                marginTop: "30px",
                marginBottom: "-53px",
                display: "flex",
                fontSize: "28px",
              }}
            ></div>
            <div className="container-event-profle">
              <div className="col-md-12">
                <Select
                  options={vehicleList}
                  value={vehicleList?.find(
                    (option) => option.value === vehicle
                  )}
                  onChange={(selectedOption) => {
                    setVehicle((prevData) => ({
                      ...prevData,
                      vehicleId: selectedOption.value,
                    }));
                  }}
                  placeholder={"Select Vehicle"}
                />
              </div>

              <div className="profle-block tab-content" id="nav-tabContent">
                {vehicleHistoryDetails?.vehicleMaintance?.length > 0 ? (
                  <>
                    <div className="containe">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row">
                            {vehicleHistoryDetails?.vehicleMaintance &&
                            vehicleHistoryDetails.vehicleMaintance.length >
                              0 ? (
                              <>
                                <div className="col-xl-12">
                                  <div>
                                    <h5
                                      style={{
                                        fontSize: "17px",
                                        fontWeight: "500",
                                        marginTop: "45px",
                                      }}
                                    >
                                      Vehicle Maintenance History:
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      height: "auto",
                                      maxHeight: "500px",
                                    }}
                                    className="w-100 overflow-auto"
                                  >
                                    {vehicleHistoryDetails?.vehicleMaintance?.map(
                                      (item) => {
                                        return (
                                          <>
                                            <VerticalTimeline>
                                              <VerticalTimelineElement
                                                className="vertical-timeline-element--work"
                                                contentStyle={{
                                                  background: "rgb(13,202,240)",
                                                  color: "rgb(14 14 14)",
                                                  height: "80px",
                                                }}
                                                contentArrowStyle={{
                                                  borderRight:
                                                    "7px solid  rgb(13,202,240)",
                                                }}
                                                date={item.last_maintancne_at}
                                                iconStyle={{
                                                  background: "rgb(13,202,240)",
                                                  color: "rgb(14 14 14)",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    lineHeight: "10px",
                                                    marginTop: "-3px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  MaintainanceKm :{" "}
                                                  {item.maintainance_at_km}
                                                </p>
                                                <p
                                                  style={{
                                                    lineHeight: "10px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginLeft: "40px",
                                                  }}
                                                >
                                                  AfterMaintananceKm :{" "}
                                                  {
                                                    item.total_km_after_maintanance
                                                  }
                                                </p>
                                              </VerticalTimelineElement>
                                            </VerticalTimeline>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <tr style={{ display: "flex", justifyContent: "center" }}>
                      <td className={`text-center`}>{`Record Not Found`}</td>
                    </tr>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            outline
            onClick={onModifyModalClose}
            className={`btn-square btn-sm`}
          >{`Close`}</Button>
        </ModalFooter>
      </Modal>

      <Modal className="" isOpen={modifyModalNotifications}>
        <>
          <div>
            <div className="inner-header py-3">
              <div className="left-block">
                <h5>Vehicle Alarm History Details</h5>
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                marginTop: "30px",
                marginBottom: "-53px",
                display: "flex",
                fontSize: "28px",
              }}
            ></div>
            <div className="container-event-profle">
              <div className="col-md-12">
                <Select
                  options={vehicleList}
                  value={vehicleList?.find(
                    (option) => option.value === vehicle
                  )}
                  onChange={(selectedOption) => {
                    setVehicle((prevData) => ({
                      ...prevData,
                      vehicleId: selectedOption.value,
                    }));
                  }}
                  placeholder={"Select Vehicle"}
                />
              </div>
              <div className="profle-block tab-content" id="nav-tabContent">
                {vehicleHistoryDetails?.vehicleAlarm?.length > 0 ? (
                  <>
                    <div className="containe">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="row">
                            {vehicleHistoryDetails?.vehicleAlarm &&
                            vehicleHistoryDetails?.vehicleAlarm?.length > 0 ? (
                              <>
                                <div className="col-xl-12">
                                  <div>
                                    <h5
                                      style={{
                                        fontSize: "17px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Vehicle Alarm History:
                                    </h5>
                                  </div>
                                  <div
                                    style={{
                                      height: "auto",
                                      maxHeight: "500px",
                                    }}
                                    onScroll={handleScroll}
                                    ref={listInnerRef}
                                    className="w-100 overflow-auto"
                                  >
                                    {vehicleHistoryDetails?.vehicleAlarm?.map(
                                      (item) => {
                                        const dateString = item.alarm_time;
                                        const momentObj = moment(dateString);
                                        return (
                                          <>
                                            <div>
                                              <VerticalTimeline>
                                                <VerticalTimelineElement
                                                  className="vertical-timeline-element--work"
                                                  contentStyle={{
                                                    background:
                                                      "rgb(13,202,240)",
                                                    color: "rgb(14 14 14)",
                                                    height: "100px",
                                                  }}
                                                  contentArrowStyle={{
                                                    borderRight:
                                                      "7px solid  rgb(13,202,240)",
                                                  }}
                                                  date={momentObj.format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )}
                                                  iconStyle={{
                                                    background:
                                                      "rgb(13,202,240)",
                                                    color: "rgb(14 14 14)",
                                                  }}
                                                >
                                                  <div className="text-col-row">
                                                    <p
                                                      style={{
                                                        lineHeight: "10px",
                                                        marginTop: "-3px",
                                                      }}
                                                    >
                                                      Device IMEI :{" "}
                                                      {item.device_imei}
                                                    </p>
                                                    <p
                                                      style={{
                                                        lineHeight: "10px",
                                                      }}
                                                    >
                                                      Speed : {item.speed}KM
                                                    </p>
                                                    <p
                                                      style={{
                                                        lineHeight: "10px",
                                                      }}
                                                    >
                                                      Driver :{item.driver_name}
                                                    </p>
                                                  </div>
                                                </VerticalTimelineElement>
                                              </VerticalTimeline>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <tr style={{ display: "flex", justifyContent: "center" }}>
                      <td className={`text-center`}>{`Record Not Found`}</td>
                    </tr>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            outline
            onClick={onModifyModalCloseNotifications}
            className={`btn-square btn-sm`}
          >{`Close`}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default RightHeader;
